import { Container, Grid, TextField, Card, CardContent, CardHeader, Box, Button } from '@material-ui/core'
import React from 'react'

export default function Enrollment() {
  const [complete, setComplete] = React.useState(false)
  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    data.append("module", window.location.hostname.split(".")[1] === "selectschoolmelbourne" ? "Select School Melbourne" : "Point Cook Tutoring")
    fetch("https://regapi.pointcooktutoring.com/api/registration", {
      method: "POST",
      body: data
    }).then(res => res.json()).then(res => {
      if (res.status_code === 200) {
        setComplete(true)
      }
    })
  }
  return (
    <Container>
      <Box p={2}>
        {complete ?
          <Card>
            <CardContent>
              <h1>Thank you!</h1>
            </CardContent>
          </Card>
          :
          <form onSubmit={handleSubmit}>
            <Grid container justify="center">
              <Grid item>
                <h1>{window.location.hostname.split(".")[1] === "selectschoolmelbourne" ? "Select School Melbourne" : "Point Cook Tutoring"}</h1>
              </Grid>
            </Grid>
            <Card>
              <CardHeader title="Student Information" />
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Full Name" fullWidth size="small" variant="outlined" required name="name" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Grade" fullWidth size="small" variant="outlined" required type="number" name="grade" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="School" fullWidth size="small" variant="outlined" required name="school" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Subject(s)" fullWidth size="small" variant="outlined" required name="subjects" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Birth Date" fullWidth size="small" variant="outlined" required type="date" InputLabelProps={{ shrink: true }} name="birthdate" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Zoom Email ID" fullWidth size="small" variant="outlined" required type="email" name="zoomid" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box p={2} />
            <Card>
              <CardHeader title="Parent/Carer Information" />
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Full Name" fullWidth size="small" variant="outlined" required name="parent_name" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Mobile Number" fullWidth size="small" variant="outlined" required type="number" name="mobile_number" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Email Address" fullWidth size="small" variant="outlined" required type="email" name="email" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="Comments/Remarks" fullWidth size="small" variant="outlined" multiline rows={3} name="remarks" />
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
            <Box p={2} />
            <Card>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item style={{ flex: 1 }}>
                    <TextField label="How did you hear about us?" fullWidth size="small" variant="outlined" name="referencec" />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <p>{window.location.hostname.split(".")[1] === "selectschoolmelbourne" ? "Select School Melbourne" : "Point Cook Tutoring"} would like to take photos/videos of students to promote our joint efforts on social media/website/print media</p>
                    <input type="radio" id="share_concent_agree" name="share_concent" value={true}></input>
                    <label for="share_concent_agree">Agree</label>
                    <input type="radio" id="share_concent_disagree" name="share_concent" value={false}></input>
                    <label for="share_concent_disagree">Disagree</label>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box p={1} />
            <Grid container justify="center">
              <Grid item>
                <Button type="submit" size="large" variant="contained" >Submit</Button>
              </Grid>
            </Grid>
          </form>
        }
      </Box>
    </Container>
  )
}
