import { Box, Button, Card, CardContent, CardHeader, Container, Grid, TextField } from '@material-ui/core'
import React from 'react'

export default function Idgen() {
    const [complete, setComplete] = React.useState(false);
    const [name, setName] = React.useState("");
    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        setName(data.get("name"));
        let pass = data.get('password');
        let cpass = data.get('confirm_password')
        let grade = data.get('grade')
        if (pass !== cpass) {
            alert("Confirm password does not match");
            return
        }
        if(!grade){
            alert("Please select your grade");
            return
        }
        fetch("https://api.pointcooktutoring.com/api/selfRegistration", {
            method: "POST",
            body: data
        }).then(res => res.json()).then(res => {
            if (res.status_code === 200) {
                setComplete(true)
            } else {
                alert(res.error)
            }
        })
    }
    return (
        <Container>
            <Box p={2}>
                {complete ?
                    <Card>
                        <CardContent>
                            <h1>Thank you!</h1>
                            <p>{`Username required for login is : `}<strong>{name.split(" ")[0].toLowerCase()}.{name.split(" ")[1].toLowerCase()}</strong></p>
                            <p>Your account is pending approval and you'll be able to access the portal only after the account is approved.</p>
                        </CardContent>
                    </Card>
                    :
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <CardHeader title="Create your login" />
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item style={{ flex: 1 }}>
                                        <TextField label="Student Full Name" fullWidth size="small" variant="outlined" required name="name" />
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>
                                        <select name="grade" style={{ width: "100%", padding: 10, borderColor: "#d3d3d3", borderRadius: 4 }}>
                                            <option value={0} selected disabled>Please select your grade</option>
                                            <option value={2}>Grade 2</option>
                                            <option value={3}>Grade 3</option>
                                            <option value={4}>Grade 4</option>
                                            <option value={5}>Grade 5</option>
                                            <option value={6}>Grade 6</option>
                                        </select>
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>
                                        <TextField label="Parent Email" fullWidth size="small" variant="outlined" required name="email" type="email" />
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>
                                        <TextField label="Password" fullWidth size="small" variant="outlined" required name="password" type="password" />
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>
                                        <TextField label="Confirm Password" fullWidth size="small" variant="outlined" required name="confirm_password" type="password" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Box p={2} />
                        <Grid container justify="center">
                            <Grid item>
                                <Button type="submit" size="large" variant="contained" >Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box>
        </Container>
    )
}
