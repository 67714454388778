import React from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import Enrollment from './Enrollment'
import Idgen from './Idgen'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Enrollment}/>
        <Route path="/idgen" exact component={Idgen}/>
      </Switch>
    </Router>
  )
}
